@import url('https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');
@import "https://cdn.jsdelivr.net/npm/bulma@0.9.4/css/bulma.min.css";
.App{
  background-color: #1B49AD;
  min-height: 100vh;
  display: flex;
  
}

.app-container{
  width: 100%;
  max-width: 500px;
  padding: 40px 10px;
}

.main-logo-container{
  display: flex;
  justify-content: center;
  margin-bottom: 20px;
}

body::-webkit-scrollbar {
  display: none;
}

