.column{
    display: flex;
    justify-content: center;
    border: 2px solid #fff;
    margin: 20px;
    border-radius: 5px;
    cursor:pointer;
}

.column h2{
    text-align: center;
}