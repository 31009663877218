
.promo-container{
    font-family: 'montserrat', sans-serif;
    border: 2px solid #fff;
    border-radius: 5px;
    padding: 15px;
}

h2{
    font-size:25px;
    color: #fff;
    font-weight: 700;
}

ul{
    color:#fff;
    font-weight: 500;
    font-size: 20px;
    line-height: 24px;
    margin: 0px 30px 25px;
    list-style-image: url(../../img/check.svg);
}

li{
    margin:15px 0px;
    padding-left: 15px;
}

.promo-descr{
    color:#fff;
    font-weight: 300;
    font-size: 19px;
    line-height: 21px;
    margin-bottom: 25px;
}

.month{
    font-size: 15px;
    font-weight: 400;
    margin-left: 5px;
}

.price{
    font-size: 40px;
    color:#fff ;
    font-weight: 700;
    text-align: center;
    margin: 10px 0px 10px;
}

.badge{
    color: #01FFFD;
    padding: 5px;
    text-align: center;
    border-radius: 5px;
    margin-top: 5px;
    font-size: 15px;
    font-weight: 700;
    }

.button{
    width: 100%;
    margin-top: 10px;
    font-size:20px;
    font-weight: 700;
    color:#1B49AD;
    transition-duration: 450ms;
}

.button:hover{
    color: #1B49AD;
    transform: scale(1.02);
   
}

.alert{
    text-align: center;
    color: #fff;
    font-size: 20px;
    line-height: 24px;
    font-weight:700;
    margin: 2px;
    padding: 10px;
    background-color: #FF0000;
    border-radius: 5px;
}