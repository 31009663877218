.service-parts{
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
}

.service-part{
    width: 100%;
    background-color: #fff;
    border-radius: 15px;
    padding: 20px 15px;
    margin: 10px 0px;
    cursor: pointer;
    box-shadow: 0px 6px 0px #FF9C36;
    display: flex;
    align-items: center;
}

.service-part img{
    width: 40px;
    margin-right: 10px;
}

.service-text{
    font-family: 'Montserrat', sans-serif;
    color: #212112;
    font-weight: 600;
    font-size: 20px;
    text-align: center;
    display: flex;
    justify-content: center;
    width: 100%;
}

.plus{
    color: #212112;
    font-weight: 600;
    font-size: 20px;
}