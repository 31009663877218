.typ {
    background: #FF9C36;
    text-align: center;
    min-height: 100vh;
    font-family: 'montserrat', sans-serif;
    display: flex;
    justify-content:center;
    align-items: center;
    flex-direction: column;
    color:#fff;
}

.title {
    font-family: 'montserrat', sans-serif;
    font-weight: 800;
    padding-top: 20px;
    margin-bottom: 20px;
    justify-content: center;
    color: #fff;
    
}

.subtitle{
    color:#fff;
    font-weight: 500;
}

.typ img{
    width: 190px;
}
