.people{
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
}

.people-number{
    width: 47%;
    background-color: #fff;
    border-radius: 15px;
    padding: 15px;
    margin: 10px 0px;
    cursor: pointer;
    box-shadow: 0px 6px 0px #FF9C36;
    display: flex;
    justify-content: center;
}


.people-text{
    font-family: 'Montserrat' , sans-serif;
    margin-top: 10px;
    color: #212112;
    font-weight: 600;
    font-size: 20px;
}