
.App{
  background-color: #1B49AD;
  min-height: 100vh;
  display: flex;
  justify-content: center;
  
}

.app-container{
  width: 100%;
  max-width: 500px;
  padding: 40px 20px;
}

.main-logo-container{
  display: flex;
  justify-content: center;
  margin-bottom: 15px;
}

body::-webkit-scrollbar {
  display: none;
}