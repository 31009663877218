.form-container{
    width: 100%;
    display: flex;
    justify-content:center;
}

.container{
    display: flex;
    flex-direction: column;
    text-align: center;
  
}
.label{
    color: #fff;
    font-family: 'montserrat', sans-serif;
    font-weight: 400;
    font-size: 13px;

}