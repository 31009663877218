.title{
    font-family: 'Montserrat', sans-serif;
    font-style: normal;
    font-weight: 700;
    font-size: 21px;
    line-height: 28px;
    display: flex;
    align-items: center;
    text-align: center;
    color: #fff;
    margin-bottom: 0;
}