.op-select{
    width: 100%;
    border: 2px solid #fff;
    border-radius: 5px;
    margin: 25px 0px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    padding: 20px;
    
}

p{
    color: #fff;
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 500;
    font-size: 17px;
    text-align: center;
    margin-right: 15px;
}

