.list-container{

    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    margin-bottom: 25px;
}
.logo-container{
    width: auto;
    min-width: 42%;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #fff;
    border-radius: 5px;
    margin: 0.60rem 0.75rem;
    min-height: 60px;
    padding: 6px;  
    cursor: pointer;
    transition-duration: 450ms;
}

.logo-container:hover{
    transform: scale(1.05);
    
}

.altro{
    color: #1b49ad ;
    text-align: center;
    font-size: 18px;
    font-weight: 600;
}

  

.logo{
    height: auto;
    width: auto;
}

.subtitle {
    font-family: 'Montserrat', sans-serif;
    font-size: 20px;
    text-align: center;
}
