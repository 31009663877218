

.app {
  background-color: #1b49ad;
  width: 100%;
  min-height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  font-family: 'Montserrat', sans-serif;
}


.description{
    padding: 1.5rem 0.7rem;
}

.description .heading,
.subtheading {
  text-align: left;
  
}

.heading {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 700;
  font-size: 2rem;
  line-height: 156.9%;
  text-transform: none;
  color: #fff;
  margin: 1.5rem 0;
}

.subheading{
    font-size: 1.5rem;
    color: #fff;
    font-weight: 500;
}


.select-voucher {
  display: inline-flex;
  width: 100%;
  justify-content: space-between;
  padding: 1rem 0.5rem 2.5rem;
  }

.select-voucher-desktop{
    display: none;
}

.option {
  background-color: #1b49ad;
  border: 2px solid #fff;
  padding: 0.9rem 1.3rem;
  border-radius: 23px;
  font-weight: 600;
  font-size: 0.750em;
  color: #fff;
  transition-duration: 200ms;
    width: 32%;
}


.option:focus{
    background-color: #fff;
    color: #1b49ad;
}

.option:active{
    transform: scale(0.8);
}

.content{
   width: 100%;
}

.promo-header{
    width: 100%;
    padding: 0.5rem 1.2rem;
    border-radius: 23px 23px 0 0;
    display: grid;
    grid-template-columns: 0.75fr 1fr;
    align-items: baseline;
    color: #fff;
    row-gap: 0.1rem;
    box-shadow: 0px -7px 23px rgba(0, 0, 0, 0.25);
}

.voucher-text{
    font-size: 0.7rem;
    font-weight: 500;
}

.voucher-title{
    font-size: 1.2rem;
    font-weight: 700
}

.voucher-subtitle{
    font-size: 1.1rem;
    font-weight: 600
}

.promo-content{
    background-color: #fff;
    padding: 1rem 0.750rem;
    
}

.promo-title{
    font-size: 2rem;
    font-weight: 700;
    line-height: 2.3rem;
}

.promo-title[datatype="fr"]::after{
    content: url('./../../img/fr.svg');
    margin-left:0.5rem ;
}

.promo-title[datatype="f"]::after{
    content: url('./../../img/f.svg');
    margin-left:0.5rem ;
}

.promo-price{
    margin: 1.2rem 0;
    font-weight: 600;
    font-size: 0.8rem;
    line-height: 130%;
}

.big-price{
    font-size: 3rem;
    line-height: 3.5rem;
    font-weight: 600;
}



.unica-header{
    font-size: 0.8rem;
    text-align: center;
    font-weight: 700;
    background-color: #1b49ad;
    color: #fff;
    border-radius: 5px 5px 0 0;
    padding: 0.2rem;
}

.unica-content{
    border: 2px solid #1b49ad;
    border-radius: 0 0 5px 5px;
    padding: 0.4rem 0.7rem;
    font-size: 0.8rem;
    line-height: 130%;
    font-weight: 500;
}

.unica-content .big-price{
    font-size: 2.5rem;
    font-weight: 500;
}

.cta{
    text-align: center;
    padding: 2rem 0 1rem;
    font-size: 0.950rem;
    font-weight: 600;
    
}

.cta-button{
    width: 100%;
    background-color: #2CBE80;
    text-align: center;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    gap: 0.5rem;
    justify-content: center;
    padding: 0.8rem ;
    border-radius: 12px;
    color: #fff;
    font-size: 1.125rem;
    font-weight: 700;
    margin-bottom: 1rem;
    box-shadow: 0px 4px 13px rgba(0, 0, 0, 0.25);
    cursor: pointer;
    transition-duration: 200ms;
}

.cta-button:hover{
    background-color: #1da96e;
}

.cta-button:active{
    box-shadow: none;
    transform: scale(0.9);
}

.feature-list{
    display: flex;
    align-items: flex-start;
    flex-direction: column;
    margin-top: 1.3rem;
}

.feature{
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    gap: 1rem;
    margin: 0.7rem 0rem
}

.form-container{
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 1rem;
    justify-content: center;
    color: #fff;
    max-width: 800px;
}

.more{
    display: none;
}

@media (min-width: 85em){
    .app{
        flex-direction: row;
        padding: 5rem 10rem;
        justify-content: center;
        align-items: center;
    }

    .description{
        justify-content: center;
        align-items: center;
        max-width: 700px;
        text-align: left;
    }
    .content{
        width: auto;
    }

    .more{
    display: block;
    text-align: center;
    text-decoration: underline;
    cursor: pointer
}

    .feature-list{
        display: none;
    }


    .content-container{
        padding-left: 10rem;
        max-width: 800px;
        min-width: 700px;
    }
    .select-voucher{
        display: none;
    }

    .option{
        margin-bottom: 1rem;
        padding: 1rem;
        text-align: left;
        display: inline-flex;
        align-items: center;
        font-size: 1rem;
        justify-content: space-between;
        cursor: pointer;
        width: 100%;

    }

    .select-voucher-desktop{
        display: flex;
        flex-direction: column;
        margin: 1rem 10rem 0 0 ;
    }

    .heading{
        font-size: 2.5rem;
        
    }

    .subtitle{
        font-size: 1.3rem;
    }

    .voucher-text,  .promo-price, .unica-content, .cta{
        font-size: 1rem;
    }

    .promo-content{
        border-radius: 0 0 23px 23px;
        margin-bottom: 1rem;
    }
}

@media (min-width: 750px) and (max-width: 1500px){
    .description, .content-container{
        max-width: 750px;
        min-width: 550px;
    }

    .content{
        display: flex;
        justify-content: center;
        min-width: 650px
    }

    .promo-content{
        border-radius: 0 0 23px 23px;
        margin-bottom: 1rem;
    }

   

    
}
    
