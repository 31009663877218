@import url('https://fonts.googleapis.com/css2?family=Passion+One:wght@400;700;900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@400;600&display=swap');

.main{
    font-family: 'Passion One', cursive;
   min-height: 100vh;
   background-image: url("./../../img/gas/gas-bg.png");
   background-position: center ;
   background-size: cover;
   display: flex;
   align-items: top;
   justify-content: center;
}

.main-container{
    width: 100%;
    max-width: 480px;
    display: flex;
    padding: 15vh 20px;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    text-align: center;
}

h3{
    color: #fff;
    font-size: 20px;
    line-height: 22px;
    font-weight: 600;
    font-family: 'Montserrat', sans-serif;
}

h1{
    color: #fff;
    font-size: 45px;
    line-height: 47px;
    font-weight: 600;
    text-transform: uppercase;
    margin: 5px 0px 20px;
}

@media screen and (min-width: 480px){
    h1{
        font-size: 50px;
        line-height: 43px;
    }
}

.text-blue{
    color: #FF9C36;
}

.text-black{
    color: #212121;
}

.logo-bolletta{
    position: absolute;
    width: 100%;
    display: flex;
    justify-content: center;
    padding: 30px;
}

.logo-bolletta img{
    width: 150px
}

.button-1{
    background: #FFFFFF;
    box-shadow: 0px 6px 0px #FF9C36;
    border-radius: 17px;
    color: #212112;
    margin: 50px 0px;
    padding: 14px 38px;
    font-weight: 700;
    font-size: 20px;
    transition-duration: 500ms;
    cursor: pointer;
    font-family: 'Montserrat', sans-serif;

}

.button-1:hover{
    box-shadow: 0px 3px 0px #FF9C36;
}